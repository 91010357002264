import React from "react"
import Img from "gatsby-image"
import LayoutHockey from '../components/layout_hockey'
//import { Link } from 'gatsby'

/*
const NewsPage = ({data}) => (
  <LayoutHockey>
  <div id="content-wrap">
    <ul id="news">
      { data.allNodeHockeycommunity.edges.map(({ node }) => (
        <li className="news-item">
          <h3 className="header">{ node.title }</h3>
          { node.relationships.field_image != null &&
            <span className="news-img"><Img fluid={node.relationships.field_image.localFile.childImageSharp.fluid} /></span>
          }
          <div className="content" dangerouslySetInnerHTML={{ __html: node.body.value }} />
        </li>
      ))}
    </ul>
  </div>
  </LayoutHockey>
)
*/

const printContent = (node) => {
  if (node) {
    return (
      <li className="news-item">
        <h3 className="header">{ node.title }</h3>
        { node.relationships.field_image != null &&
          <span className="news-img"><Img fluid={node.relationships.field_image.localFile.childImageSharp.fluid} /></span>
        }
        <div className="content" dangerouslySetInnerHTML={{ __html: node.body.value }} />
      </li>
    )
  }
  else {
    return (<li />)
  }
}

const NewsPage = ({data}) => (
  <LayoutHockey>
  <div id="content-wrap">
    <ul id="news">
      { data.allNodeHockeycommunity.edges.map(({ node }) => ( printContent(node) ))}
    </ul>
  </div>
  </LayoutHockey>
)

export default NewsPage


export const query = graphql`
  query allNodeHockeycommunity {
    allNodeHockeycommunity (filter: {status: {eq: true}}, sort: {fields: field_update_release_date, order: DESC}) {
      edges {
        node {
          id
          title
          body {
            value
            format
            processed
            summary
          }
          relationships {
              field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 792, maxHeight: 387) {  
                    src
                    srcSet
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`